/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { usePreSignupUserDataProperty } from '../UserProvider';
import { SegmentProvider } from '../wrappers/SegmentProvider';
import { QuotesSidebar, QUOTE_TYPES } from '../Onboarding/QuotesSidebar';
import { FullScreenOnboarding } from '../Onboarding/FullScreenOnboarding';
import { SignupPane } from '../Onboarding/SignupPane';
import { GoalPane } from '../Onboarding/GoalPane';
import { SpecificGoalPane } from '../Onboarding/SpecificGoalPane';
import { GoalSidebar } from '../Onboarding/GoalSidebar';
import { goalToMakeTimeFor } from '../../functions/shared/constants';
import { NowLaterPane } from '../Onboarding/NowLaterPane';
import { CelebrationScreen } from '../IntentionOnboarding/CelebrationScreen';
import { ChallengesPane } from './ChallengesPane';

const SoloWorkerOnboardingWrapper = () =>
  <SegmentProvider eventLabel={'SoloWorker'}>
    <SoloWorkerOnboardingContent />
  </SegmentProvider>

const SoloWorkerOnboardingContent = () => {
  const makingTimeFor = usePreSignupUserDataProperty('makingTimeFor')
  const makingTimeForText = goalToMakeTimeFor[makingTimeFor]
  const nowLaterTitle = `When would you like to focus on ${makingTimeForText || 'your goal'}?`
  const celebrationTitle = "Book another session"

  const celebrationScreen = {
    name: "CelebrationPane",
    MainContent: <NowLaterPane title={celebrationTitle} key="celebration" showUpcoming/>,
    sideDesktopContent: <CelebrationScreen />,
  }
  const stepScreens = [
    {
      name: "ChallengesPane",
      MainContent: <ChallengesPane key="challenges" />,
      sideDesktopContent: <QuotesSidebar quoteType={QUOTE_TYPES.MEDIA} />
    },
    {
      name: "SignupPane",
      MainContent: <SignupPane key='signup' titleOverride="Sign up to save your preferences" />,
      navType: 'none'
    },
    {
      name: "GoalPane",
      MainContent: <GoalPane key="goal" />,
      sideDesktopContent:
        makingTimeFor === undefined ? (
          <QuotesSidebar quoteType={QUOTE_TYPES.MEDIA} />
        ) : (
          <GoalSidebar selectedGoal={makingTimeFor} step="goal" />
        ),
    },
      {
        name: "SpecificGoalPane",
        MainContent: <SpecificGoalPane key="specific-goal" />,
        sideDesktopContent: (
          <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" />
        ),
        mobileContent: (
          <GoalSidebar selectedGoal={makingTimeFor} step="specific-goal" mobileView />
      )
    },
    {
      name: "NowLaterPane",
      MainContent: <NowLaterPane title={nowLaterTitle} key="now-later" showUpcoming={false} />,
      sideDesktopContent: <QuotesSidebar quoteType={QUOTE_TYPES.USER} />,
    },
  ];

  return (
    <FullScreenOnboarding 
      stepScreens={stepScreens} 
      celebrationScreen={celebrationScreen}
    />
  )
}

export const SoloWorkerOnboarding = SoloWorkerOnboardingWrapper
