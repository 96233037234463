/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import dayjs from 'dayjs';
import { loadStripe } from '@stripe/stripe-js';
import { createContext, useContext, useState } from 'react';
import { useParams } from "react-router"
import { useMediaQuery } from 'react-responsive';
import { Helmet } from "react-helmet"
import { Badge, Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { baseURL } from './firebase';
import { UserContext } from './UserProvider';
import { LoginSignupModal } from './Sessions/modals/LoginSignupModal';
import { useModal } from './wrappers/MagnificentlyMunificentModalManager';
import { FC_BACKGROUND_GRAY, FC_DARK_BLUE, FC_LIGHTER_BLUE, FC_LIGHT_BLUE, NEUTRAL_100 } from './emotionVariables';
import { FlowButton, BUTTON_STYLES,LightGrayText, Text, TEXT_STYLES, SIZE_PRESETS, LinkStyledText, TabbedInterface, UnstyledLink } from './components/flowComponents';
import { NavBar as LoggedInNavBar } from './commonLayout/NavBar';

import { useAutomaticallyFetch } from './fetch/helpers';
import { useBookMultipleSessions, useCreateCheckoutSessionForClub, useGetClub, useGetClubSessions, useIndicateInterestInClub, useJoinClub } from './fetch/endpoints';
import { MainLoading } from './commonLayout/MainLoading';
import { SadOctoPage } from './commonLayout/SadOctoPage';
import { useSendSegmentEvent } from './wrappers/SegmentProvider';
import { CLUB_MAX_MEMBERS, CLUB_MEMBERSHIP_ACTIVE_STATUSES, CLUB_MEMBERSHIP_STATUSES, CLUB_PAYMENT_TYPES } from '../functions/shared/constants';
import { getUserHasBookedSession, getUserIsUnauthed, getSessionIsJoinable, truncateTextWithEllipses, getClubIsOpenForSignups } from '../functions/shared/helpers';
import { EventCard } from './Sessions/EventCard';
import { toastError, toastSuccess } from './components/utils/toaster';

const isProd = process.env.REACT_APP_PROJECT_ID === 'flowclub-21'
const stripePublishableKey =  isProd ? 'pk_live_51J2J1oE0xMKXSRCd2PAwCHavKCBAtOhPpBhRhI70Tck0rmlKbmcADOjJW1yzOdp2K7BAURyrMBtkuhvU17dFp2SP0042556Df5' : 'pk_test_51J2J1oE0xMKXSRCdEE7zpmfTffa3nS9oYljkFEnZJG6Yy0tXnJUtjTSC7OYuongmtr1a9fafV0eIsiFkZIXMLBYJ00YMHfZXiQ'
const stripePromise = loadStripe(stripePublishableKey)


const mobileViewMediaQuery = {query: '(max-width: 1199px)'}
export const ClubContext = createContext(null);

const paymentTypeIcon = {
  [CLUB_PAYMENT_TYPES.FREE]: "😃",
  [CLUB_PAYMENT_TYPES.PAID]: "💲",
  [CLUB_PAYMENT_TYPES.TIPPING_ENCOURAGED]: "✨"
}

const clubBadgeStyle = css`
  height: 24px;
  padding: 0px 8px;
  border-radius: 24px;
  border: 1px solid #F1F1F1;
  margin: 8px;
  color: ${NEUTRAL_100};

  background: #FFF;
`

export const ClubPaymentBadge = ({ paymentType }) => {
  return (
    <div css={css`
      ${clubBadgeStyle};
    `}>
      {paymentTypeIcon[paymentType]} {paymentType}
    </div>
  )
}

const ClubSeatsLeftBadge = ({ seatsLeft, startDate }) => {
  const seatsLeftText = seatsLeft <= 0 ? "🏠 Full house!" : seatsLeft === 1  ? "🔥 1 seat left" : `🔥 ${seatsLeft} seats left`
  return seatsLeft <= 2 && startDate ? (
    <div
      css={css`
        ${clubBadgeStyle};
      `}
    >
      {seatsLeftText}
    </div>
  ) : null
}

const ClubActionButton = ({ blackText = false}) => {
  const club = useContext(ClubContext)
  const { user } = useContext(UserContext)
  const { setActiveModal } = useModal()
  const { name, startDate, price, paymentType, status, members, refetchClubData, id, stripeCheckoutSessionId } = club
  const seatsLeft = CLUB_MAX_MEMBERS - members
  const textColor = blackText ? '#000000' : '#ffffff';

  const sendSegmentEvent = useSendSegmentEvent()

  const { performFetch: indicateInterest, fetching: indicateInterestLoading, error: indicateInterestError } = useIndicateInterestInClub()
  const { performFetch: joinClub, fetching: joinClubLoading, error: joinClubError } = useJoinClub()
  const {performFetch: getCheckoutSession, fetching: getCheckoutSessionLoading, error: getCheckoutSessionError} = useCreateCheckoutSessionForClub()
  const loading = indicateInterestLoading || joinClubLoading || getCheckoutSessionLoading
  const clubIsOpenForSignups = getClubIsOpenForSignups(club)
  let action;
  const isFree = price === undefined || price === null || price === 0;
  const freeMessage =
    paymentType === CLUB_PAYMENT_TYPES.TIPPING_ENCOURAGED
    ? "free (tips welcome)"
    : "free";
  const registerAction = `Register for ${isFree ? freeMessage : `$${price / 100}`}`;
   
  if (status === CLUB_MEMBERSHIP_STATUSES.ACTIVE) {
    action = "You're in!";
  } else if (status === CLUB_MEMBERSHIP_STATUSES.HOST) {
    action = "Thanks for hosting!";
  } else if (status === CLUB_MEMBERSHIP_STATUSES.INTERESTED) {
    if (!startDate) {
      action = "You'll receive details when this club launches";
    } else if (seatsLeft <= 0) {
      action = "We'll notify you if spots open up";
    } else if (!getClubIsOpenForSignups(club)) {
      action = "We'll keep you updated!";
    } else {
      action = registerAction;
    }
  } else if (status === CLUB_MEMBERSHIP_STATUSES.COMPLETED) {
    action = "Completed";
  } else if (getClubIsOpenForSignups(club)) {
    if (status === CLUB_MEMBERSHIP_STATUSES.PENDING_PAYMENT) {
      action = "Complete payment to reserve your seat";
    } else {
      action = registerAction;
    }
  } else {
    action = "Indicate interest";
  }

  const doClubAction = async () => {
    const nonUserOrUnauthedUser = user === null || getUserIsUnauthed(user)
    sendSegmentEvent('Clicked Register for Club', {clubName: name, paymentType: paymentType, redirectedToSignup: nonUserOrUnauthedUser})
    if (nonUserOrUnauthedUser) {
      setActiveModal(LoginSignupModal, {
        showLoginModeLink: true,
        onSuccess: () => { window.location.reload() }
      })
      return
    }
    if (!clubIsOpenForSignups) {
      const { success } = await indicateInterest({ clubId: id })
      if (success) {
        refetchClubData()
      }
    }
    else if (paymentType === CLUB_PAYMENT_TYPES.PAID) {
      const stripe = await stripePromise;
      const { success, result: sessionId } = await getCheckoutSession({ clubId: id })
      if (success) {
        stripe.redirectToCheckout({ sessionId })
      }
    } else {
      const { success} = await joinClub({ clubId: id })
      if (success) {
        refetchClubData()
      }
    }
  }

  return (
    <>
    <FlowButton
      sizePreset={SIZE_PRESETS.CHUNKY}
      buttonStyle={BUTTON_STYLES.GREEN}
      onClick={doClubAction}
      loading={loading}
      customCss={css`min-width: 234px;`}
      disabled={
        status === CLUB_MEMBERSHIP_STATUSES.ACTIVE ||
        status === CLUB_MEMBERSHIP_STATUSES.HOST ||
        status === CLUB_MEMBERSHIP_STATUSES.COMPLETED ||
        (status === CLUB_MEMBERSHIP_STATUSES.INTERESTED &&!clubIsOpenForSignups)
      }
    >
      {action}
    </FlowButton>
    {stripeCheckoutSessionId !== undefined &&
      !CLUB_MEMBERSHIP_ACTIVE_STATUSES.has(status) && (
        <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${textColor}`}>
          If you just checked out, try refreshing this page. If you're
          running into issues, please{" "}
          <a css={css`text-decoration: underline; color: ${textColor};`} href="mailto:help@flow.club">email help@flow.club</a> and we'll
          get it fixed!
        </Text>
      )}
      {seatsLeft <= 0 ?
      <div css={css`margin: 10px 0px;`}>
        <Text style={TEXT_STYLES.CAPTION} customCss={css`color: ${textColor};`}>🏠 Full house! Indicate interest to join the waitlist and get notified for the next one.</Text>
      </div>
      : null
      }
    </>
  )
}

const clubBackgroundImageCss = (image) => (
  css`
    background-color: ${FC_LIGHT_BLUE};
    ${image ?
      css`
          background-image: linear-gradient(
            0deg,
            #000 -7.11%,
            rgba(0, 0, 0, 0.76) -7.1%,
            rgba(0, 0, 0, 0.65) 18.85%,
            rgba(0, 0, 0, 0) 96.7%
          ),
          url(${image});
        background-size: cover;
        background-position: center;
        background-color: lightgray;
      `
      : css`
        background-image: linear-gradient(
          to left,
          ${FC_LIGHTER_BLUE},
          ${FC_LIGHT_BLUE}
        );
      `};
  `
)

const MobileClubHeader = () => {
  const club = useContext(ClubContext)
  const { user } = useContext(UserContext)
  const { name, startDate, paymentType, members } = club
  const seatsLeft = CLUB_MAX_MEMBERS - members

  return (
    <div
      css={css`
        margin: 0px;
        ${clubBackgroundImageCss(club.image)};
        height: 157px;
        position: relative;
      `}
    >
      <div css={css`
        padding: 64px 0px 64px 47px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

      `}>
      {user !== null && <span css={css`align-self: flex-end`}><LoggedInNavBar onlyHamburgerMenu dark/></span>}
      </div>
      <div css={css`
        position: absolute;
        left: 24px;
        top: 145px;
  
        display: flex;
        align-items: center;
        gap: 4px;
      `}>
         <ClubSeatsLeftBadge seatsLeft={seatsLeft} startDate={startDate} />
        <ClubPaymentBadge paymentType={paymentType} />
      </div>
    </div>
  )
}


const DesktopClubHeader = () => {
  const club = useContext(ClubContext)
  const { name, startDate, paymentType, members } = club
  const seatsLeft = CLUB_MAX_MEMBERS - members

  return (
    <div
      css={css`
        margin: 24px 0px;
        background-color: ${FC_LIGHT_BLUE};
        ${clubBackgroundImageCss(club.image)};
        height: 357px;
        border-radius: 16px;
      `}
    >
      <div
        css={css`
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          padding: 96px 48px 48px 48px;
        `}
      >
        <div css={css`
          gap: 4px;
          display: inline-flex;
        `}>
        <ClubSeatsLeftBadge seatsLeft={seatsLeft} startDate={startDate} />
        <ClubPaymentBadge paymentType={paymentType} />
        </div>
        <Text style={TEXT_STYLES.APP_H1} customCss={css`color: #ffffff;`}>
          {name}
        </Text>
        <ClubActionButton />
      </div>
    </div>
  );
}

export const MAX_DESCRIPTION_CHARACTERS_DESKTOP = 300
export const MAX_DESCRIPTION_CHARACTERS_MOBILE_GALLERY = 140

export const ClubDescription = ({ description, maxChars = MAX_DESCRIPTION_CHARACTERS_DESKTOP }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = (e) => { e.preventDefault(); e.stopPropagation(); setExpanded(!expanded); }
  const expandable = description !== null && description.length > maxChars
  const displayText = expandable && !expanded ? truncateTextWithEllipses(description, maxChars) : description;

  return (
    <Text style={TEXT_STYLES.BODY_SUBTITLE}>
      {displayText}
      {expandable && <LinkStyledText customCss={css`
        margin-left: 8px;
      `} onClick={toggleExpand}>
        {expanded ? 
        "See Less" :
        "See More"
        }
      </LinkStyledText>}
    </Text>
  )
}

const ClubContent = () => {
  const club = useContext(ClubContext)
  const { user } = useContext(UserContext)
  const { hostImage, fullName, name, description, resourcesDescription, startDate, endDate, clubHostDescription, hostSlug, sessions, sessionAgenda, status } = club
  const startDateFormatted = startDate ? dayjs(startDate).format('ddd, MMM D') : null
  const endDateFormatted = endDate ? dayjs(endDate).format('ddd, MMM D') : null
  const mobileView = useMediaQuery(mobileViewMediaQuery)
  const sendSegmentEvent = useSendSegmentEvent()
  const canSeeClubSessions = user !== null && CLUB_MEMBERSHIP_ACTIVE_STATUSES.has(status)
  return (
    <div
      css={css`
        display: flex;
        padding: ${mobileView ? '40px 24px 24px 24px' : '24px 48px 48px 48px'};
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        min-height: 100px;
        ${!mobileView && css`border-radius: 16px;`}
        background: #fff;
      `}
    >
      {mobileView && <Text style={TEXT_STYLES.APP_H4}>{name}</Text>}
      <div
        css={css`
          display: flex;
          flex-direction: ${mobileView ? 'column' : 'row'};
          ${mobileView && css`gap: 24px;`}
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Text style={TEXT_STYLES.APP_H6}>
            {startDate
              ? `${startDateFormatted} - ${endDateFormatted}`
              : "Starts soon"}
          </Text>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 24px;
            color: ${FC_DARK_BLUE};
          `}
        >
          <img
            src={hostImage}
            alt={fullName}
            css={css`
              width: 42px;
              height: 42px;
              border-radius: 50%;
            `}
          />
          <Text
            customCss={css`
              font-weight: 700;
            `}
          >
            {fullName}
          </Text>
          <Link to={`/host/${hostSlug}`}>
            <Text
              style={TEXT_STYLES.BODY_2}
              customCss={css`
                text-transform: uppercase;
              `}
            >
              View Profile
            </Text>
          </Link>
        </div>
      </div>
      <div
        css={css`
          padding: 8px 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <ClubDescription description={description} maxChars={mobileView ? MAX_DESCRIPTION_CHARACTERS_MOBILE_GALLERY : MAX_DESCRIPTION_CHARACTERS_DESKTOP}/>
        {mobileView && <ClubActionButton blackText />}
        {resourcesDescription.length > 0 && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
              margin-top: 8px;
            `}
          >
            <Text style={TEXT_STYLES.BODY_SUBTITLE}>
              What you'll get:
            </Text>
            <ul>
              {resourcesDescription.map((resource, i) => (
                <li key={i}>{resource}</li>
              ))}
            </ul>
          </div>
        )}
        {canSeeClubSessions ? (
          <Sessions />
        ) : (
          <>
            {sessionAgenda && (
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  flex-direction: column;
                  margin-top: 8px;
                `}
              >
                <Text style={TEXT_STYLES.BODY_BOLD_SUBTITLE}>Agenda:</Text>
                <Text style={TEXT_STYLES.BODY_SUBTITLE}>{sessionAgenda}</Text>
              </div>
            )}
            {sessions !== undefined && sessions.length > 0 && (
              <div
                css={css`
                  display: flex;
                  gap: 8px;
                  flex-direction: column;
                `}
              >
                <Text style={TEXT_STYLES.BODY_BOLD_SUBTITLE}>
                  Sessions ({sessions.length}):
                </Text>
                <LightGrayText>
                  All times in {dayjs().format("z")} ({dayjs.tz.guess()})
                </LightGrayText>
                <ul>
                  {sessions.map(({ startTime, title }, i) => (
                    <li key={i}>
                      {dayjs(startTime).format("ddd, MMM D h:mm A")}: {title}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
        <div
          css={css`
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            border-radius: 16px;
            background: ${FC_BACKGROUND_GRAY};
          `}
        >
          <Text style={TEXT_STYLES.BODY_1_BOLD}>About {fullName}:</Text>
          <Text style={TEXT_STYLES.BODY_1}>{clubHostDescription}</Text>
          <Link to={`/host/${hostSlug}`}>
            <FlowButton
              buttonStyle={BUTTON_STYLES.GREEN_OUTLINE}
              onClick={() =>
                sendSegmentEvent(
                  "Viewed host profile from bottom of club page"
                )
              }
            >
              View Profile
            </FlowButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

const GuidedClubHeaderAndContent= () => {
  const club = useContext(ClubContext)
  const { userIsClubOwner } = club
  const mobileView = useMediaQuery(mobileViewMediaQuery)

  return (
    <div
      css={css`
        flex-grow: 1;
      `}
    >
      {mobileView ? <MobileClubHeader /> : <DesktopClubHeader />}
      {userIsClubOwner ? 
        <ClubOwnerContentWrapper />
      :
        <ClubContent />
      }
    </div>
  );
}

const ClubHostContent = () => {
  const club = useContext(ClubContext)
  const { user } = useContext(UserContext)
  const { activeClubMembers, clubSessions, numInterestedClubMembers } = club
  const mobileView = useMediaQuery(mobileViewMediaQuery)

  const statusOrder = {
    [CLUB_MEMBERSHIP_STATUSES.HOST]: 1,
    [CLUB_MEMBERSHIP_STATUSES.ACTIVE]: 2,
    [CLUB_MEMBERSHIP_STATUSES.PENDING_PAYMENT]: 3,
    [CLUB_MEMBERSHIP_STATUSES.INTERESTED]: 4,
  }
  const clubMembersSorted = activeClubMembers.sort((a, b) => a.clubStatus === b.clubStatus ? a.joinedClub - b.joinedClub : statusOrder[a.clubStatus] - statusOrder[b.clubStatus])
  const thStyle = css`
    position: sticky;
    top: 0;
  `
  const stats = [
    { title: "Active Members", value: activeClubMembers.length },
    { title: "Interested Members", value: numInterestedClubMembers },
  ]

  return (
    <div
      css={css`
        display: flex;
        padding: ${mobileView ? '40px 24px 24px 24px' : '24px 48px 48px 48px'};
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        min-height: 100px;
        ${!mobileView && css`border-radius: 16px;`}
        background: #fff;
      `}
    >
      <Text style={TEXT_STYLES.APP_H3}>Club Members</Text>
      <div css={css`
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media (min-width: 768px) {
          & > * {
            flex: 1 0 calc(33.333% - 72px);
            max-width: calc(33.333% - 72px);
          }
        }
      `}>
        {stats.map(({ title, value }) => (
         <div
            key={title}
            css={css`
              background: white;
              padding: 24px;
              border: 1px solid #F1F1F1;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
            `}
          >
            <Text style={TEXT_STYLES.APP_H3}>{value}</Text>
            <Text style={TEXT_STYLES.SUBTITLE_1}>{title}</Text>
          </div>
        ))}

      </div>
      <Text style={TEXT_STYLES.CAPTION}>If you plan to email everyone together, please BCC them unless they've agreed to share their email with the group.</Text>
      <a href={`mailto:${user.email}?bcc=${activeClubMembers.map(({ displayName, email}) => `${displayName}%20<${email}>`).join(', ')}`} target="_blank" rel="noopenner noreferrer">
        <FlowButton buttonStyle={BUTTON_STYLES.GREEN} sizePreset={SIZE_PRESETS.CHUNKY} customCss={css`min-width: 234px;`}>Email All Club Members (bcc'ed)</FlowButton>
      </a>
      <Table striped bordered css={css`position: relative;`}>
        <thead>
          <tr>
            <th css={thStyle}>Name</th>
            <th css={thStyle}>Email</th>
            <th css={thStyle}>Location</th>
            <th css={thStyle}>Joined Club</th>
            <th css={thStyle}>Upcoming Sessions Booked</th>
            <th css={thStyle}>Sessions Attended so far</th>
            <th css={thStyle}>Sessions No-showed</th>
            <th css={thStyle}>Sessions Canceled</th>
          </tr>
        </thead>
        <tbody>
        {clubMembersSorted.map(({ id, displayName, email, location, joinedClub, upcomingParticipants, completedParticipants, noShowParticipants, canceledParticipants }, _i) => {
          const canceledParticipantsWithReason = canceledParticipants.filter(({reason}) => reason !== null)
          const completedParticipantsWithFeedbackOrFlowScore = completedParticipants.filter(({hostFeedback, flowScore }) => hostFeedback !== null || flowScore !== null)
          return (
          <tr key={id}>
            <td>{displayName}</td>
            <td><a href={`mailto:${displayName}%20<${email}>`} target="_blank" rel="noopenner noreferrer">{email}</a></td>
            <td>{location}</td>
            <td>{dayjs(joinedClub).format('ddd MMM D')}</td>
            <td>{upcomingParticipants.length}</td>
            <td>
              {completedParticipants.length}
              {completedParticipantsWithFeedbackOrFlowScore.length > 0 &&
              <ul>
              {completedParticipantsWithFeedbackOrFlowScore.map(({ id, start, hostFeedback, flowScore}) => (
                <li key={id}>{dayjs(start).format('ddd MMM D')}{hostFeedback !== undefined || flowScore !== undefined ? ': ' : ''}{hostFeedback !== undefined ? hostFeedback : ''}{flowScore !== undefined ? `${hostFeedback !== undefined ? ' | ' : ''}Flow score: ${flowScore}` : ''}</li>
              ))}
              </ul>
              }
            </td>
            <td>{noShowParticipants.length}</td>
            <td>
              {canceledParticipants.length}
              {canceledParticipantsWithReason.length > 0 &&
              <ul>
              {canceledParticipantsWithReason.map(({id, start, reason, otherReason}) => (
                <li key={id}>{dayjs(start).format('dddd, MMMM D')}: {reason === 'Other...' ? otherReason : reason}</li>
              ))}
              </ul>
              }
            </td>
          </tr>)
        }
        )}
        </tbody>
      </Table>
    </div>
  )
}

const ClubOwnerContentWrapper = () => {
  const tabs = [
    {
      "title": "Public View",
      Content: <ClubContent key="club-content"/>,
    },
    {
      "title": "View as Club Host",
      Content: <ClubHostContent key="club-host-content"/>,
    },
  ]
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  return (
    <TabbedInterface
      tabs={tabs}
      activeTabIndex={activeTabIndex}
      setActiveTabIndex={setActiveTabIndex}
    />
  )
}

const Sessions = () => {
  let { name, slug } = useContext(ClubContext)
  const { result: sessions, performFetch, fetching } = useAutomaticallyFetch(useGetClubSessions,
    { slug },
    { dependencies: [slug] },
  )

//  const { performFetch: bookAllSessions, fetching: bookAllSessionsLoading } = useBookMultipleSessions()

  const bookableSessions = sessions === null ? [] : sessions.filter(({ userSessionStatus }) => getSessionIsJoinable(userSessionStatus))

  const bookedSessions = sessions === null ? [] : sessions.filter(({ userSessionStatus }) => getUserHasBookedSession(userSessionStatus))

  const refetchClubSessions = () => performFetch({ slug })
  const sendSegmentEvent = useSendSegmentEvent()
  
  // const bookAllSessionsAction = async ({ sessionIds }) => {
  //   sendSegmentEvent('Clicked Book All Sessions', { clubName: name, numSessions: sessionIds.length })
  //   const { result, success } = await bookAllSessions({ sessionIds, clubSlug: slug })
  //   if (success) {
  //     const { sessionsBooked } = result
  //     const successfullyBooked = sessionsBooked.filter(({success}) => success)
  //     const failedToBook = sessionsBooked.filter(({success}) => !success)
  //     if (successfullyBooked.length > 0) {
  //       toastSuccess({ message: `Booked ${successfullyBooked.length} session${successfullyBooked.length > 1 ? 's' : ''}!` })
  //     }
  //     if (failedToBook.length > 0) {
  //       toastError({ message: `Failed to book ${failedToBook.length} session${failedToBook.length > 1 ? 's' : ''}.` })
  //     }
  //     await refetchClubSessions()
  //   } else {
  //     toastError({ message: 'We ran into an error booking your sessions. Please try again later.' })
  //   }
  // }

  return (
    <div>
      <Text style={TEXT_STYLES.BODY_BOLD_SUBTITLE} customCss={css`margin-bottom: 32px;`}>Sessions{sessions !== null ? ` (${sessions.length})` : ''}</Text>
      <LightGrayText>All times in {dayjs().format("z")} ({dayjs.tz.guess()})</LightGrayText>
      {sessions === null || sessions.length === 0 ?
        <Text>No sessions yet! Check back soon.</Text> :
        <>
        {/* {bookedSessions.length > 0 &&
          <Text
            style={TEXT_STYLES.BODY_1}
            customCss={css`margin-bottom: 16px;`}
          >
            You're booked into {bookableSessions.length === 0 ? 'all ' : ''}{bookedSessions.length} session{bookedSessions.length > 1 ? 's' : ''}. {bookableSessions.length > 0 ? 'Book the rest!' : ''}
          </Text>
        }
        {bookableSessions.length > 0 &&
          <FlowButton
            fillAvailableWidth
            buttonStyle={BUTTON_STYLES.PRIMARY}
            sizePreset={SIZE_PRESETS.CHUNKY}
            onClick={() => bookAllSessionsAction({ sessionIds: bookableSessions.map(({id}) => id) })}
            loading={bookAllSessionsLoading}
            customCss={css`margin-bottom: 16px;`}
          >
            Book {bookableSessions.length > 1 ? 'all ' : ''}{bookableSessions.length} session{bookableSessions.length > 1 ? 's' : ''}
          </FlowButton>
        } */}
        {sessions.map((event, index) => <EventCard
          key={event.id}
          isFirst={index === 0}
          isLast={index === sessions.length - 1}
          event={event}
          onActionSuccess={refetchClubSessions}
          showDate
        />)}
        </>}
    </div>
  )
}

export const Club = () => {
  const { slug } = useParams()
  const mobileView = useMediaQuery(mobileViewMediaQuery)

  const { result: club, error, performFetch } = useAutomaticallyFetch(useGetClub,
    { slug },
    { dependencies: [slug] },
  )
  const refetchClubData = () => performFetch({ slug })

  if (club === null) {
    return (
      <>
        {error === null ? <MainLoading /> : <SadOctoPage />}
      </>
    )
  }
  return (
    <div css={css`
      background-color: #F8F8F8;
      min-height: 100vh;
      padding-bottom: 40px;
    `}>
      <Helmet>
        <title>{club.name} on Flow Club</title>
        <meta name="description" content={club.description} />
        <meta name="twitter:description" content={club.description} />
        <meta property="og:image" content={club.image} />
        <meta name="twitter:image" content={club.image} />
        <link rel="canonical" href={`${baseURL}/guided-club/${slug}`} />
      </Helmet>
      <ClubContext.Provider value={{...club, refetchClubData }}>
        <Container css={css`
          ${mobileView && 'padding: 0px;'}
        `}>
          <div css={css`
            ${!mobileView && 'display: flex;'}
            ${!mobileView && 'margin-top: 16px;'}
          `}>
            <GuidedClubHeaderAndContent />
        </div>
        </Container>
      </ClubContext.Provider>
    </div>
    )
}