import { createContext, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { functionsHost } from "../firebase";
import { useAuthToken, usePreviousSticky, useRefresh } from "../utils";
import * as Sentry from "@sentry/react";
import { UserContext } from "../UserProvider";

export interface PostRequestReturnValues {
  fetching: boolean,
  result: any,
  statusCode: number | null,
  error: Error | null
}

export interface PerformFetchReturnValues {
  success: boolean,
  result: any,
  statusCode: number | null,
  error: Error | null
}

export enum REQUEST_QUEUING_MODES {
  NONE,
  APPEND,
  REPLACE
}

// Takes an endpoint path,
// returns the fetch function (which itself takes a set of arguments to be used in the request body) and a set of variables representing the request's status
export const usePostRequest = (
  endpoint: string,
  options: { requiresAuth?: boolean, queuingMode?: REQUEST_QUEUING_MODES } = { requiresAuth: true, queuingMode: REQUEST_QUEUING_MODES.NONE }
) => {
  const getAuthToken = useAuthToken()

  const requestPath = `${functionsHost}${endpoint}`
  const getRequestOptions = (authToken, dynamicArguments = {}) => ({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // just some silly syntax to not add an Authorization property at all if an authToken isn't present
      ...authToken !== null ? { Authorization: 'Bearer ' + authToken } : {}
    },
    body: JSON.stringify(dynamicArguments)
  })


  const defaultStatus = { fetching: false, result: null, error: null, statusCode: null }
  const [fetchStatus, setFetchStatus] = useState<PostRequestReturnValues>({ ...defaultStatus })
  // A weird hack to make sure performFetch always has access to up-to-date status values, and not out of date closured ones
  const fetchStatusRef = useRef<PostRequestReturnValues>({ fetching: false, result: null, statusCode: null, error: null })
  fetchStatusRef.current = { ...fetchStatus }
  // same but for auth token
  const getAuthTokenRef = useRef(getAuthToken)
  getAuthTokenRef.current = getAuthToken

  const queuedRequests = useRef<[Function?]>([])

  const requestOverride = useFetchOverride(endpoint)

  const performFetch: (dynamicArguments?: object) => Promise<PerformFetchReturnValues> = async (dynamicArguments = {}) => {
    let authToken
    try {
      authToken = await getAuthTokenRef.current()
    } catch (error) {
      return { success: false, result: null, statusCode: null, error: (error as { a, code, message }).message }
    }

    const makeRequest = async (dynamicArguments) => {
      setFetchStatus({ ...fetchStatusRef.current, fetching: true })
      
      // this is a terrible name but at a certain point you run out of synonyms for "do thing"
      const makeRequestOverridden = requestOverride !== null ?
        async () => (await requestOverride(dynamicArguments)) ?? { result: null, error: null, statusCode: null } :
        async () => await fetchButCoolerAndWithErrorHandling(requestPath, getRequestOptions(authToken, dynamicArguments))

      const { result, error, statusCode } = await makeRequestOverridden()
      const statusValues = { result, error, statusCode, fetching: false }
      setFetchStatus(statusValues)
      fetchStatusRef.current = { ...statusValues }

      if (queuedRequests.current.length > 0) {
        (queuedRequests.current.shift() as Function)()
      }

      return { result, error, statusCode, success: error === null }
    }

    // Five cases:
    // Auth token not present (and required):                   discard new request
    // Request already in-flight, and no queuing mode set:      discard new request
    // Request already in-flight, and APPEND queuing mode set:  add new request to list of pending requests. send it once the in-flight request and all other pending requests finish
    // Request already in-flight, and REPLACE queuing mode set: discard any existing pending requests, if already present, and add new request as a pending request. send it once the in-flight request finishes
    // No request currently in-flight:                          make new request directly. the default in most cases
    //
    // In the future maybe it'll be useful to have a mode that cancels & discards the in-flight request as well, but currently not sure where that'd be useful
    if (authToken === null && options.requiresAuth) {
      console.warn(`Fetch request to endpoint ${endpoint} not made -- auth token required and none was provided`)
      return { success: false, result: null, statusCode: null, error: 'required auth token not present' }
    }
    if (fetchStatusRef.current.fetching) {
      if (options.queuingMode === REQUEST_QUEUING_MODES.NONE) {
        return { success: false, result: null, statusCode: null, error: 'fetch already in progress' }
      } else if (options.queuingMode === REQUEST_QUEUING_MODES.APPEND) {
        const queuedRequestPromise = new Promise<PerformFetchReturnValues>(async (resolve) => {
          queuedRequests.current.push(async () => {
            const responseStatusValues = await makeRequest(dynamicArguments)
            resolve(responseStatusValues)
          })
        })
        return queuedRequestPromise
      } else if (options.queuingMode === REQUEST_QUEUING_MODES.REPLACE) {
        const queuedRequestPromise = new Promise<PerformFetchReturnValues>(async (resolve) => {
          queuedRequests.current = [async () => {
            const responseStatusValues = await makeRequest(dynamicArguments)
            resolve(responseStatusValues)
          }]
        })
        return queuedRequestPromise
      }
    }

    return await makeRequest(dynamicArguments)
  }

  return {
    performFetch,
    ...fetchStatus
  }
}

// I'm really good at names
export const fetchButCoolerAndWithErrorHandling = async (requestPath, requestOptions) => {
  const logError = error => {
    console.error(`Error while making request to ${requestPath}`)
    console.error(`Request body: ${requestOptions.body}`)
    console.error(error);
    Sentry.captureException(error, { extra: { requestBody: requestOptions.body, requestPath } });
  }

  try {
    const response = await fetch(requestPath, requestOptions)
    const data = await response.json()
    if (data.error === null) {
      return { result: data.result, error: null, statusCode: response.status }
    } else {
      logError(data.error)
      return { result: null, error: data.error, statusCode: response.status }
    }
  } catch (error: any) {
    logError(error);
    return { result: null, error: error.message, statusCode: null }
  }
}

// A helper function for calling components to use to determine when their request succeeded
// since a boolean 'success' status is no longer returned from requests
export const useFetchSucceeded = (fetching, error) =>{
  const previousFetching = usePreviousSticky(fetching)
  return previousFetching && !fetching && error === null
}

// A thin wrapper around useEffect() and usePostRequest() to slightly simplify/standardize loading data on page (or any component) load
export const useAutomaticallyFetch: (
  usePostRequestHook: Function,
  dynamicArguments?: object,
  options?: { dependencies?: Array<any>, transform?: Function, condition?: boolean, refetchEvery?: number, ignoreAuth?: boolean }
) => PostRequestReturnValues = (
  usePostRequestHook,
  dynamicArguments = undefined,
  { dependencies = [], transform = data => data, condition = true, refetchEvery = null, ignoreAuth = false } = {}
) => {
  // By default, we'll likely want to (attempt to) refetch data when our auth user changes, such as after a login or logout
  const { authUser, loginInProgress } = useContext(UserContext)
  const requestHookReturnValues = usePostRequestHook()

  const lastRefreshedAt = useRefresh(refetchEvery)

  const [lastGoodResult, setLastGoodResult] = useState(requestHookReturnValues.result)

  useEffect(() => {
    if (condition && (!loginInProgress || ignoreAuth)) {
      requestHookReturnValues.performFetch(dynamicArguments)
    }
  }, [...dependencies, condition, lastRefreshedAt, authUser, loginInProgress, ignoreAuth])

  useEffect(() => {
    let mounted = true
    if (requestHookReturnValues.result !== null && mounted) {
      const transformed = transform(requestHookReturnValues.result);
      setLastGoodResult(transformed)
    }
    return () => {
      mounted = false
    }
  }, [requestHookReturnValues.result])

  const transformedResult = requestHookReturnValues.result !== null ? transform(requestHookReturnValues.result) : null
  return { ...requestHookReturnValues, result: transformedResult, lastGoodResult }
}

export const FetchOverrideContext = createContext({
  overrides: []
})
interface EndpointOverrideDefinitions {
  // this endpoint being defined by a random string is a bit icky, but meh
  [endpointName: string]: (requestArguments: any) => Promise<{
    result: any,
    statusCode: number | null,
    error: Error | null
  }>
}
export const FetchContainer: (args: { children: ReactElement, endpointOverrides?: EndpointOverrideDefinitions }) => ReactElement =
  ({ children, endpointOverrides = [] }) => {
    const parentFetchOverrideContextValue = useContext(FetchOverrideContext)
    const { overrides: existingOverrides } = parentFetchOverrideContextValue

    return <FetchOverrideContext.Provider value={{ overrides: { ...existingOverrides, ...endpointOverrides } }}>
      {children}
    </FetchOverrideContext.Provider>

  }
const useFetchOverride = (endpointName: string) => useContext(FetchOverrideContext).overrides[endpointName] ?? null