/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from 'react-bootstrap';
import { FC_BLUE, FC_DARK_BLUE } from '../emotionVariables';
import { UserContext } from '../UserProvider';
import { InterstitialContext } from '../Onboarding/WelcomeInterstitial';
import { useContext, useState } from 'react';
import { BUTTON_STYLES, FlowButton } from '../components/flowComponents';
import { usePreSignupUserDataProperty } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';

const SOLO_WORK_CHALLENGES = {
  DISTRACTION: "It's easy to get distracted 📱",
  LOW_ENERGY: "Feeling unmotivated or low energy 😔",
  FOCUS: "It's harder to stay focused 🎯",
  TIME_MANAGEMENT: "Time tends to slip away from me ⏰",
  ACCOUNTABILITY: "Miss having some accountability 🤝",
  ISOLATION: "Feeling of isolation 🧍",
  OTHER: "Something else ✨"
}

export const ChallengesPane = () => {
  const { setPreSignupUserData } = useContext(UserContext)
  const { largeScreenView, goToNextPane } = useContext(InterstitialContext)
  const [selectedChallenges, setSelectedChallenges] = useState(usePreSignupUserDataProperty('challenges') || [])
  const sendSegmentEvent = useSendSegmentEvent()

  const goalButtonCss = (option) => css`
    height: 48px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    &:hover, &:active, &:focus {
      background-color: ${FC_BLUE};
      color: white;
    }
    ${selectedChallenges?.includes(option) && `
      background-color: ${FC_BLUE};
      color: white;
    `}
  `

  const toggleChallenge = (challenge) => {
    const newChallenges = selectedChallenges ? [...selectedChallenges] : []
    const index = newChallenges.indexOf(challenge)
    
    if (index === -1) {
      newChallenges.push(challenge)
    } else {
      newChallenges.splice(index, 1)
    }
    
    sendSegmentEvent("Challenge Selected", { challenge })
    setSelectedChallenges(newChallenges)
    setPreSignupUserData({ challenges: newChallenges })
  }

  return (
    <>
      <h2 css={css`
        color: ${FC_DARK_BLUE};
        margin-bottom: 16px;
        text-align: center;
      `}>
        What makes working solo harder than it needs to be?
      </h2>
      <div css={css`
        letter-spacing: 0.15px;
        text-align: center;
        color: ${FC_DARK_BLUE};
        margin-bottom: ${largeScreenView ? 32 : 24}px;
      `}>
        Select all that apply. We'll help make your next solo work session easier and more productive.
      </div>
      <div css={css`
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
      `}>
        {Object.entries(SOLO_WORK_CHALLENGES).map(([key, label]) => (
          <FlowButton
            key={key}
            customCss={goalButtonCss(key)}
            onClick={() => toggleChallenge(key)}
            fillAvailableWidth
            buttonStyle={BUTTON_STYLES.OUTLINE_DARK}
          >
            {label}
          </FlowButton>
        ))}
        <Button
          onClick={() => goToNextPane()}
          disabled={!selectedChallenges?.length}
          variant="primary"
        >
          Continue
        </Button>
      </div>
    </>
  )
}
