/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserProvider';
import { TrialBanner } from '../shared/components/trialbanner';
import { EmailVerificationBanner } from '../shared/components/EmailVerificationBanner';
import Footer from '../commonLayout/Footer';
import { useUpdateNotification } from '../components/UpdateNotification';
import { NavBar } from '../commonLayout/NavBar';
import { SideBar } from '../commonLayout/SideBar';
import { useWelcomeRedirect } from './useWelcomeRedirect';
import { SubscriptionStatusModals } from './SubscriptionStatusModals';
import { useMediaQuery } from 'react-responsive';
import { NAV_SIDEBAR_MIN_WIDTH, NAV_SIDEBAR_WIDTH } from '../../functions/shared/constants';

export function DashboardApp({ children: routes }) {
  const { user } = useContext(UserContext)
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userToggled, setUserToggled] = useState(false);
  const [rightMobileMenuOpened, setRightMobileMenuOpened] = useState(false)
  const sidebarAlwaysOpen = useMediaQuery({ minWidth: NAV_SIDEBAR_MIN_WIDTH });

  useEffect(() => {
    if (user && user.sessions > 0 && sidebarAlwaysOpen) {
      setSidebarOpen(true);
    } else if (!sidebarAlwaysOpen && !userToggled) {
      setSidebarOpen(false);
    }
  }, [sidebarAlwaysOpen, userToggled, user]);

  const toggleSidebar = () => {
    setUserToggled(true);
    if (!sidebarAlwaysOpen) {
      if (rightMobileMenuOpened) {
        setRightMobileMenuOpened(false);
      }
      setSidebarOpen(!sidebarOpen);
    }
  };


  useUpdateNotification()

  useWelcomeRedirect()

  return (
    <div>
      <div css={css`
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      `}>
        <NavBar sidebarAlwaysOpen={sidebarAlwaysOpen} toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setRightMobileMenuOpened={setRightMobileMenuOpened} rightMobileMenuOpened={rightMobileMenuOpened} />
        <div css={css`
          margin-left: ${sidebarOpen ? NAV_SIDEBAR_WIDTH : '0'}px;
          margin-top: 62px;
        `}>
            <TrialBanner />
            <EmailVerificationBanner />
            <SideBar sidebarAlwaysOpen={sidebarAlwaysOpen} sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {routes}
          <Footer/>
          <SubscriptionStatusModals />
        </div>
      </div>
    </div>
  );
}