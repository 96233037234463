/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useContext, useEffect, useMemo, useState, memo } from 'react';
import Timer from './Timer';
import BrowserUnsupported from '../daily/daily-components/BrowserUnsupported/BrowserUnsupported';
import DailyIframe from '@daily-co/daily-js';
import { useRoomExp } from '@daily-co/daily-react';
import EventContext from '../EventContext';
import PiPContext from '../PiPContext';
import { UserContext } from '../UserProvider';
import { useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { Card, Text, TEXT_STYLES } from '../components/flowComponents';
import { CHAT_MESSAGE_COLORS_HSL, FC_LIGHT_BLUE, FC_GREEN, NEUTRAL_20, NEUTRAL_90 } from '../emotionVariables';
import { Pip, useCreatePipStream } from './Pip';
import { Attendees } from '../daily/daily-components/Attendees/Attendees';
import Chat from '../daily/daily-components/Chat/Chat';
import { HostClaimNotification } from './HostClaimNotification';
import { SessionStageProgressBars } from './SessionStageProgressBars';
import Call from '../daily/daily-components/Call/Call';
import Tray from '../daily/daily-components/Tray/Tray';
import { useGoals } from '../components/GoalList/useGoals';
import { useUpdateSessionGoals } from '../fetch/endpoints';
import { useDebounced, usePrevious } from '../utils';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from "react-helmet";
import { usePopConfetti } from '../wrappers/ConfettiProvider';
import { MusicSharing } from './MusicSharing/MusicSharing';
import { GoalListWithCopyPaste } from '../components/GoalList/GoalListWithCopyPaste';
import { DoNotDisturb } from './DoNotDisturb/DoNotDisturb';

export const JoinedSessionView = ({ sessionTimerData, timerText, timerTextColor, existingGoals, stages, sessionName, userIsHost }) => {
  const { event }= useContext(EventContext)

  const [pictureInPictureActive, setPictureInPictureActive] = useState(false)
  const togglePictureInPicture = () => {
    setPictureInPictureActive(active => !active)
  }

  const { removeBeforeUnload } = sessionTimerData

  const mobileView = useMediaQuery({query: '(max-width: 674px)'})
  
  return (
    <PiPContext.Provider value={{
      pictureInPictureActive,
      togglePictureInPicture,
      setPictureInPictureActive,
    }}>
      <div css={css`display: flex; ${mobileView ? 'min-height' : 'height'}: 100vh; flex-grow: 1;`}>
        <LeftSidebar
          sessionTimerData={sessionTimerData}
          timerText={timerText}
          timerTextColor={timerTextColor}
          eventId={event.id}
          existingGoals={existingGoals}
          stages={stages}
          sessionName={sessionName}
          userIsHost={userIsHost}
        />
        {!mobileView && <div css={css`height: 100vh; flex-grow: 1; overflow: hidden;`}>
            <VideosArea sessionTimerData={sessionTimerData} stages={stages}/>
        </div>}
      </div>
      <HostClaimNotification removeBeforeUnload={removeBeforeUnload} />
    </PiPContext.Provider>
  )
}

const LeftSidebar = ({ sessionTimerData, timerText, timerTextColor, eventId, existingGoals, stages, sessionName, userIsHost }) => {
  const {
    minutesElapsedThisStage,
    sessionStarted,
    sessionFinished,
    currentStage,
    activeWorkingTime,
    nextStageIsActiveWorkingTime,
    sessionWorkingTimeStarted,
  } = sessionTimerData
  const { event, activeUserParticipant: participant } = useContext(EventContext)
  const { pictureInPictureActive, setPictureInPictureActive } = useContext(PiPContext)
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})
  const smallHeightView = useMediaQuery({query: '(max-height: 500px)'})
  const [ currentGoal, setCurrentGoal ] = useState(null)
  const currentStageName = stages[currentStage] !== undefined ? stages[currentStage].name : ''
  const sessionNameStageOrCurrentGoal = (
    sessionStarted && !sessionFinished ?
    (activeWorkingTime && currentGoal !== undefined && currentGoal !== null ? currentGoal.text : currentStageName) :
    sessionName
  )

  const pipStream = useCreatePipStream({ sessionFinished, goalText: sessionNameStageOrCurrentGoal, timerText, active: pictureInPictureActive, userIsHost, onDeactivate: () => setPictureInPictureActive(false) })
  const { onCanvasMount, dailyVideoElement, startStream: startPictureInPicture } = pipStream

  useEffect(() => {
    const pipSupported = 'pictureInPictureEnabled' in document && document.pictureInPictureEnabled
    if (!pipSupported) {
      return
    }
    if (pictureInPictureActive) { 
      startPictureInPicture()
    } else if (document.pictureInPictureElement) {
      document.exitPictureInPicture()
    }
  }, [pictureInPictureActive])

  return (
    <div css={css`
      min-height: inherit;
      background-color: ${NEUTRAL_20};
      display: flex;
      flex-direction: column;
      padding: 16px;
      width: ${mobileView ? '100%' : '405px'};
      flex-shrink: 0;
    `}>
      <div css={css`
        height: 100%;
        ${mobileView && css`padding-bottom: 60px;`}; // to make space for the tray
        
        display: flex;
        flex-direction: column;
        gap: 10px;
      `}>
      <Helmet>
        <title>{timerText} {sessionNameStageOrCurrentGoal}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Helmet>
        <Card customCss={css`background-color: white; ${(mobileView || smallHeightView) && css`padding: 8px;`}`}>
        {sessionNameStageOrCurrentGoal !== sessionName && !mobileView && !smallHeightView && <div css={css`
          font-family: 'Sato';
          font-weight: 500;
          font-size: 20px;
          letter-spacing: 0.15px;
          color: ${NEUTRAL_90};
        `}>
          {sessionName}
        </div>}
          <Timer
            sessionStarted={sessionStarted}
            sessionFinished={sessionFinished}
            sessionWorkingTimeStarted={sessionWorkingTimeStarted}
            nextStageIsActiveWorkingTime={nextStageIsActiveWorkingTime}
            text={timerText}
            textColor={timerTextColor}
            userIsHost={userIsHost}
          />
          <div css={css`
            font-family: 'Sato';
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0.15px;
            color: ${NEUTRAL_90};
          `}>
            {sessionNameStageOrCurrentGoal}
          </div>
          <SessionStageProgressBars stages={stages} minutesElapsedThisStage={minutesElapsedThisStage} currentStage={currentStage} />
        </Card>
        { event.eventTypeId === 'Phone Free' &&
          <Card customCss={css`background-color: white; padding: ${mobileView ? 8 : 12}px;`}>
            <DoNotDisturb participant={participant} />
          </Card>
        }
        <Card customCss={css`background-color: white; padding: ${mobileView ? 8 : 12}px;`}>
          <MusicSharing />
        </Card>
        {mobileView && <FirstTimerWelcomeMessage
          stages={stages}
          sessionTimerData={sessionTimerData}
        />}
        <Tabs eventId={eventId} existingGoals={existingGoals} sessionTimerData={sessionTimerData} setCurrentGoal={setCurrentGoal} stages={stages} />
      </div>
      {mobileView && <div css={css`
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background: ${NEUTRAL_20};
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
      `}>
        <Tray />
      </div>}
      <Pip onCanvasMount={onCanvasMount} dailyVideoElement={dailyVideoElement} />
    </div>
  )
}

const Tabs = ({ eventId, existingGoals, sessionTimerData, setCurrentGoal, stages }) => {
  const { activeWorkingTime } = sessionTimerData
  const { newChatMessages } = useContext(EventContext)
  const mobileView = useMediaQuery({query: '(max-width: 674px)'})
  const [activeTab, setActiveTab] = useState(0)
  const smallHeightView = useMediaQuery({query: '(max-height: 500px)'})
  
  const desktopTabs = [
    { name: "My Goals", Content: <MyGoals eventId={eventId} existingGoals={existingGoals} setCurrentGoal={setCurrentGoal} /> },
    { name: "Chat", Content: <Chat active={activeTab === 1} activeWorkingTime={activeWorkingTime} />, showDot: newChatMessages },
    { name: "Attendees", Content: <Attendees /> },
  ]

  const MobileVideoContent = memo(() =>
    <div css={css`min-height: 400px;`}>
      <VideosArea sessionTimerData={sessionTimerData} mobileView stages={stages} />
    </div>
  )
  const tabs = useMemo(() => (mobileView ? [
    { name: "Videos", Content: <MobileVideoContent /> },
    ...desktopTabs,
  ] : desktopTabs), [mobileView, newChatMessages, activeTab, activeWorkingTime])

  return (
    <>
      <div css={css`display: flex; gap: 4px;`}>
        {tabs.map(({ name, showDot }, index) => (
          <div
            key={name}
            onClick={() => setActiveTab(index)}
            css={css`
              cursor: pointer;
              flex: 1;
              background-color: ${activeTab === index ? FC_LIGHT_BLUE : 'transparent'};
              border-radius: 12px;
              height: 40px;

              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
            `}
          >
            <Text style={TEXT_STYLES.SUBTITLE_2}>{name}</Text>
            {showDot &&
              <div css={css`position: absolute; top: 8px; right: 20px; width: 12px; height: 12px; border-radius: 100%; background-color: ${FC_GREEN};`} />
            }
          </div>
        ))}
      </div>
      <Card customCss={css`background-color: white; flex: 1; overflow: auto; ${(mobileView || smallHeightView) && css`padding: 8px;`}`}>
        {tabs.map(({ Content }, index) =>
          <div css={index !== activeTab && css`display: none;`} key={index}>
            {Content}
          </div>
        )}
      </Card>
    </>
  )
}

const MyGoals = ({ eventId, existingGoals, setCurrentGoal }) => {
  const goalsState = useState(existingGoals)
  const goalsData = useGoals(goalsState)
  const { performFetch } = useUpdateSessionGoals()
  const [updateParticipantGoals] = useDebounced(performFetch, 2000, true)
  const initial = usePrevious(null) === undefined
  useEffect(() => {
    setCurrentGoal(goalsData.currentGoal)
    if (!initial) {
      updateParticipantGoals({ eventId, goals: goalsData.goals, updateCalendarEvent: false })
    }
  }, [goalsData.goals])

  return (
    <GoalListWithCopyPaste goalsData={goalsData} />
  )
}

const getSessionStageMessage = ({ sessionWorkingTimeStarted, sessionWorkingTimeFinished, activeWorkingTime, chatOnly }) => {
  if (!sessionWorkingTimeStarted) {
    if (chatOnly) {
      return "Welcome to Flow Club! Share your goals in the chat, and let's focus together."
    } else {
      return "Hello! It's goal-sharing time. Share what you're looking to get done and let's tackle them together!"
    }
  }
  else if (sessionWorkingTimeFinished) {
    return "This session is ending and we are celebrating each other's progress. Join another session?"
  } else {
    if (activeWorkingTime) {
      return "Welcome! You're in the thick of the quiet work time. Say hi in chat, list your tasks, and join in the flow."
    } else {
      return "Hello there! You've joined during the break. Drop a hello in the chat, plan out your tasks, and dive in when the timer begins again."
    }
  }
}

const showFirstTimerMessageDuration = 5 * 60 * 1000 // 5 minutes

const FirstTimerWelcomeMessage = ({ sessionTimerData, stages }) => {
  const { activeWorkingTime, currentStage } = sessionTimerData
  const { sessionWorkingTimeStarted, sessionWorkingTimeFinished, event, activeUserParticipant } = useContext(EventContext);
  const { chatOnly } = event
  const { user } = useContext(UserContext)
  const { sessions } = user
  const currentStageName = stages[currentStage] !== undefined ? stages[currentStage].name : ''
  const currentStageMessage = getSessionStageMessage({ sessionWorkingTimeStarted, sessionWorkingTimeFinished, activeWorkingTime, chatOnly })
  if (sessions !== 0) return null
  if (activeUserParticipant.joined !== undefined && activeUserParticipant.joined.toMillis() < Date.now() - showFirstTimerMessageDuration) return null
  return (
    <div css={css`
    text-align: center;
    color: #000000;
    font-size: 14px;
    background: #fff;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    `}>
      <b>{currentStageName}</b><br/>{currentStageMessage}
    </div>
  )
}

const SessionRoomWillCloseMessage = () => {
  const [countdown, setCountdown] = useState('');
  const { ejectDate } = useRoomExp({
    onCountdown: useCallback(({ hours, minutes, seconds }) => {
      if (hours > 0 || minutes > 5) return;
      if (minutes > 1) {
        setCountdown(`${minutes} minutes`);
        return;
      }
      setCountdown(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    }, []),
  });
  if (countdown === '') return null;
  return (
    <div css={css`
    text-align: center;
    color: #000000;
    font-size: 14px;
    background: #fff;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    `}>
      Thanks for joining! For safety and security reasons, this room will close in <b>{countdown}</b>.
    </div>
  )
}

const VideosArea = ({ sessionTimerData, mobileView = false, stages }) => {
  const { activeWorkingTime } = sessionTimerData
  const { sessionWorkingTimeFinished, isMusicMuted, hidingSelfView, mirrorSelfView, activeUserParticipant, callObject, videosAreaRef } = useContext(EventContext);
  const emitPopConfetti = usePopConfetti()
  const sendSegmentEvent = useSendSegmentEvent()

  if (!DailyIframe.supportedBrowser().supported) {
    return <BrowserUnsupported />
  }

  const videosAreaDoubleClicked = (event) => {
    if (videosAreaRef.current === null) { return } 

    const { clientX: clickedX, clientY: clickedY } = event

    const { x: areaOffsetX, y: areaOffsetY, width: clientWidth, height: clientHeight } = videosAreaRef.current.getBoundingClientRect()

    const x = clickedX - areaOffsetX
    const y = clickedY - areaOffsetY

    const normalizedX = x / clientWidth
    const normalizedY = y / clientHeight

    const color = CHAT_MESSAGE_COLORS_HSL[activeUserParticipant.chatColorIndex]

    sendSegmentEvent("Videos Area Double Clicked", { sessionWorkingTimeFinished, confettiColor: color, x: normalizedX, y: normalizedY, activeWorkingTime})
    if (activeWorkingTime) {
      return
    }

    emitPopConfetti({ x: clickedX, y: clickedY, color })
    callObject.sendAppMessage({ type: 'confetti', values: { normalizedX, normalizedY, color } }, '*');
  }

  return (
    <div ref={videosAreaRef} onDoubleClick={videosAreaDoubleClicked}
      css={css`
        display: flex;
        flex-direction: column;
        ${mobileView && css`height: 400px;`}
        ${!mobileView && css`padding: 16px; flex-grow: 1; height: 100%;`}
      `}>
      {sessionWorkingTimeFinished && <SessionRoomWillCloseMessage />}
      {!mobileView && <FirstTimerWelcomeMessage
        stages={stages}
        sessionTimerData={sessionTimerData}
      />}
      <div css={css`flex-grow: 1; overflow: hidden;`}>
        <Call
          isMusicMuted={isMusicMuted}
          activeWorkingTime={activeWorkingTime}
          sessionWorkingTimeFinished={sessionWorkingTimeFinished}
          hidingSelfView={hidingSelfView}
          mirrorSelfView={mirrorSelfView}
        />
      </div>

      <div css={css`height: fit-content; flex-shrink: 0;`}>
        {!mobileView && <Tray />}
      </div>
    </div>
  )
}