import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import UserProvider from './UserProvider'

import * as Sentry from "@sentry/react";

import { H, HighlightSegmentMiddleware } from 'highlight.run';
import { SadOctoPage } from './commonLayout/SadOctoPage';

declare global {
  interface Window { analytics: any; }
}

if (process.env.REACT_APP_ENVIRONMENT !== "local") {

  H.init('6glr5lg9', {
    backendUrl: "https://highlight.flow.club", // Proxy
    environment: process.env.REACT_APP_ENVIRONMENT,
    enableSegmentIntegration: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
    }
  })

  Sentry.init({
    dsn: "https://014f5cd393e84545a154cc28e97238d8@o880656.ingest.sentry.io/5834543",
    environment: process.env.REACT_APP_ENVIRONMENT,
  });

  window.analytics.addSourceMiddleware(HighlightSegmentMiddleware)
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      showDialog={true}
      dialogOptions={{
        title: "Whoops! Looks like our flow got interrupted.",
        successMessage: "Thanks! Refresh the page to get back into the flow.",
        subtitle2: "Please tell us what happened below or email help@flow.club directly if you need anything else.",
      }}
      fallback={<SadOctoPage title={"Whoops! Looks like our flow got interrupted."}><div>Refresh the page to get back into the flow.</div></SadOctoPage>}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
