/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Alert } from 'react-bootstrap'

import {
  useContext,
  useState,
} from "react"
import {
  NEUTRAL_80,
  NEUTRAL_90,
} from '../emotionVariables'

import { UserContext } from '../UserProvider'
import { getDaysBetween } from '../utils'
import { BUTTON_STYLES, FlowButton, Text, TextArea, TEXT_STYLES, UnstyledLink } from '../components/flowComponents'
import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider'
import { toastError, toastSuccess } from '../components/utils/toaster'
import { userEligibleForTrialExtension } from '../../functions/shared/helpers'
import { useSubmitTrialExtension } from '../fetch/endpoints'
import { TRIAL_EXTENSION_LENGTH, ONE_DAY } from '../../functions/shared/constants'


export const TrialExtension = () => {
  const [step2, setStep2] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { user } = useContext(UserContext)
  const timeSinceTrialEnded = Date.now() - user.trialEnd.toMillis()

  return (
    <SegmentProvider baseData={{ timeSinceTrialEnded }} eventLabel={"Trial Extension"}>
      <div css={css`
        padding: 64px 12px 0px 12px;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}>
        <div css={css`
          max-width: 620px;
          text-align: center;

          display: flex;
          flex-direction: column;
          align-items: center;
        `}>
           {
            (user && (userEligibleForTrialExtension(user) || isSubmitting)) ?
              (step2 ?
              <Step2 setIsSubmitting={setIsSubmitting} /> :
              <Step1 goToStep2={() => setStep2(true)} />) :
              <NotEligible />
          }
        </div>
      </div>
    </SegmentProvider>
  );
};

const NotEligible = () => (
  <>
    <Alert variant="danger" css={css` color: white;`}>
      Sorry, but you are not eligible to extend your free trial.
    </Alert>
    <UnstyledLink to={"/plans/"}>
      <FlowButton fillAvailableWidth buttonStyle={BUTTON_STYLES.DARK}>
        See plans
      </FlowButton>
    </UnstyledLink>
  </>
)


const Step1 = ({ goToStep2 }) => {
  const sendSegmentEvent = useSendSegmentEvent()

  const extendTrialClicked = () => {
    sendSegmentEvent("Extend Trial Clicked")
    goToStep2()
  }

  const notRightNowClicked = () => {
    sendSegmentEvent("Not Right Now Clicked")
  }

  return (
    <>
      <Text customCss={css`margin-bottom: 8px; color: ${NEUTRAL_80};`}>Step 1</Text>
      <Text customCss={css`margin-bottom: 16px;`} style={TEXT_STYLES.APP_H1}>Is there a task you'd like to accomplish?</Text>
      <Text customCss={css`color: ${NEUTRAL_90}; margin-bottom: 48px;`}>If there's anything you'd like to accomplish today, we'd love to extend your Flow Club trial. If not, come back when you're ready to get the most out of Flow Club.</Text>
      <div css={css`width: 320px;`}>
        <FlowButton fillAvailableWidth customCss={css`margin-bottom: 8px;`} onClick={extendTrialClicked}>Yes, extend my trial</FlowButton>
        <UnstyledLink to={"/upcoming/"} onClick={notRightNowClicked}>
          <FlowButton fillAvailableWidth buttonStyle={BUTTON_STYLES.OUTLINE_DARK}>No, not right now</FlowButton>
        </UnstyledLink>
      </div>
    </>
  )
}

const Step2 = ({ setIsSubmitting }) => {
  const sendSegmentEvent = useSendSegmentEvent()
  const { user } = useContext(UserContext)
  const [mit, setMit] = useState('')
  const { performFetch: submitTrialExtension, fetching: loading } = useSubmitTrialExtension()
  const trialExtensionInDays = TRIAL_EXTENSION_LENGTH / ONE_DAY

  const handleSubmit = async (mitSubmitted) => {
    setIsSubmitting(true)
    sendSegmentEvent("Trial Extension Requested", { mit, mitSubmitted })
    
    const { success, error } = await submitTrialExtension({ mit })
    if (success) {
      sendSegmentEvent("Extended free trial", {
        mit,
        mitSubmitted,
        daysSinceTrialEnd: user.trialEnd.toDate !== undefined ? getDaysBetween(user.trialEnd.toDate(), new Date()) : undefined,
        sessions: user.sessions,
      })
      toastSuccess({ message: `Your trial has been extended for ${trialExtensionInDays} days! Redirecting you to the schedule...` })
      window.location.href = '/upcoming/?trialExtended=true'
    } else {
      setIsSubmitting(false)
      toastError({ message: error })
    }
  }

  return (
    <>
      <Text customCss={css`margin-bottom: 8px; color: ${NEUTRAL_80};`}>Step 2 (Optional)</Text>
      <Text customCss={css`margin-bottom: 16px;`} style={TEXT_STYLES.APP_H1}>Share your task</Text>
      <Text customCss={css`color: ${NEUTRAL_90}; margin-bottom: 32px;`}>Share your task with us so we can better understand how to continue making Flow Club the best for users like you.</Text>
      <div css={css`width: 320px;`}>
        <TextArea 
          customCss={css`margin-bottom: 16px;`} 
          value={mit} 
          onChange={(event) => setMit(event.target.value)} 
        />
        <FlowButton 
          fillAvailableWidth 
          disabled={mit.length === 0} 
          loading={loading} 
          customCss={css`margin-bottom: 8px;`} 
          onClick={() => handleSubmit(true)}
        >
          Submit
        </FlowButton>
        <FlowButton 
          fillAvailableWidth 
          loading={loading} 
          buttonStyle={BUTTON_STYLES.OUTLINE_DARK} 
          onClick={() => handleSubmit(false)}
        >
          Skip
        </FlowButton>
      </div>
    </>
  )
}