/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { FC_DARK_BLUE, FC_LIGHT_BLUE } from '../emotionVariables'

import { marketingURL } from '../../functions/shared/constants'
import { useLocation } from 'react-router';
import { Fragment } from 'react';

const XIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
  </svg>
)

const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
  </svg>
)

const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
  </svg>
)

const YoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
  </svg>
)

const Footer = () => {
  const communityLinks = [
    { link: `/hosts/`, text: 'Meet Our Hosts', target: '_self'},
    { link: `/clubs/`, text: 'Explore Clubs', target: '_self'},
    { link: 'https://help.flow.club/en/categories/189185-becoming-a-flow-club-host', text: 'Host FAQ', target: '_blank'},
    { link: 'https://flowclub.notion.site/Community-Resource-Bank-c2cac1f5454c47dca92eba16f2881cd4?pvs=74', target: '_blank', text: 'Community Resource Bank' },
    { link: `${marketingURL}/community-guidelines`, text: 'Community Guidelines', target: '_blank'},
    { link: `${marketingURL}/blog`, text: 'Blog', target: '_blank' },
    { link: `${marketingURL}/wall-of-love`, text: 'Wall of Love', target: '_blank' },
  ]

  const socialLinks = [
    { link: 'https://www.linkedin.com/company/flow-club/', text: 'LinkedIn', target: '_blank', icon: <LinkedInIcon /> },
    { link: 'https://www.x.com/flowdotclub', text: '@flowdotclub', target: '_blank', icon: <XIcon /> },
    { link: 'https://www.youtube.com/@flowclub', text: 'YouTube', target: '_blank', icon: <YoutubeIcon /> },
    { link: 'https://instagram.com/flowdotclub', text: '@flowdotclub', target: '_blank', icon: <InstagramIcon /> },
    
  ]

  const resourceLinks = [
    { link: '/plans/', text: 'Pricing', target: '_self'},
    { link: `https://help.flow.club`, text: 'Help Center', target: '_blank' },
    { link: `${marketingURL}/best-work-music`, text: 'Best Work Music', target: '_blank' },
    { link: 'https://www.phonefreehour.com/', text: 'Phone Free Hour', target: '_blank' },
  ]

  const helpLinks = [
    { link: `${marketingURL}/privacy-policy`, text: 'Privacy Policy', target: '_blank' },
    { link: `${marketingURL}/cookie-policy`, text: 'Cookie Policy', target: '_blank'},
    { link: `${marketingURL}/terms-and-conditions`, text: 'Terms and Conditions', target: '_blank'},
  ]

  const currentYear = new Date().getFullYear()

  const location = useLocation()
  const hiddenPaths = ["home-onboarding", "time-onboarding", "intention-onboarding", "solo-worker-onboarding", "lounge"]
  if (hiddenPaths.some(path => location.pathname.includes(path))) {
    return null
  }

  return (
    <div css={css`
      background-color: ${FC_DARK_BLUE};
      color: #fff;
      font-size: 14px;

      padding: 20px 0px;
      a {
        color: #fff;
        &:hover {
          color: ${FC_LIGHT_BLUE};
        }
     }
    `}>
      <Container>
        <Row>
          <Col md={3} xs={12}>
            <h2 className="FlowClub-Title">
              <a href="https://www.flow.club">Flow Club</a>
            </h2>
            Made in Flow Club<br/>
            © Happy Rhinos Corporation {currentYear}<br/>
          </Col>
          <Col md={3} xs={12}>
            <b>Community</b>
            <br />
            {communityLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
            <br />
          </Col>
          <Col md={3} xs={12}>
            <b>Follow Us</b>
            <br />
            {socialLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>
                {link.icon} <span css={css`line-height: 16px; margin-left: 8px; text-align: center;margin-top: 1px; margin-bottom: 1px;`}>{link.text}</span></a><br /></Fragment>
            ))}
            <br />
            <b>Resources</b>
            <br />
            {resourceLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
            <br />
          </Col>
          <Col md={3} xs={12}>
            <a href="mailto:help@flow.club">help@flow.club</a>
            <br />
            {helpLinks.map((link) => (
              <Fragment key={link.link}><a href={link.link} target={link.target ?? '_self'} rel={link.target === '_blank' ? "noreferrer noopener" : undefined}>{link.text}</a><br /></Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;