/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {
  useState,
} from 'react'

import { useMediaQuery } from 'react-responsive';
import { FC_BACKGROUND_GRAY } from '../emotionVariables';
import { InterstitialContext } from './WelcomeInterstitial';

import { SegmentProvider, useSendSegmentEvent } from '../wrappers/SegmentProvider';
import { STEP_STATUS, StepsStatus } from '../TimeOnboarding/Steps';
import { SIDEBAR_MIN_WIDTH } from '../Onboarding/FullScreenSidebar'

const FullScreenOnboardingDataWrapper = ({ stepScreens, celebrationScreen }) => {

  return (
    <SegmentProvider eventLabel={'Onboarding'}>
      <FullScreenOnboardingContent
        stepScreens={stepScreens}
        celebrationScreen={celebrationScreen}
      />
    </SegmentProvider>
  )
}

const FullScreenOnboardingContent = ({
  stepScreens,
  celebrationScreen,
}) => {

  const initialSteps = stepScreens.map(({ name, MainContent, navType}, index) => ({
    status: index === 0 ? STEP_STATUS.CURRENT : STEP_STATUS.PENDING,
    name,
    MainContent,
    navType
  }))

  const [steps, setSteps] = useState(initialSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [ showCelebrationScreen, setShowCelebrationScreen ] = useState(false)

  const advanceStep = (numSteps = 1) => {
    const nextStep = currentStep + numSteps
    if (nextStep >= stepScreens.length) {
      setShowCelebrationScreen(true)
    }
    sendSegmentEvent(`Advanced to step ${nextStep} in onboarding`)
    setCurrentStep(nextStep);
    window.scrollTo({ top: 0, left: 0 })
    const newSteps = steps.map((step, index) => {
      if (index < nextStep) return { status: STEP_STATUS.COMPLETED };
      if (index === nextStep) return { status: STEP_STATUS.CURRENT };
      return { status: STEP_STATUS.PENDING };
    });
    setSteps(newSteps);
  };

  const goBackOneStep = () => {
    const prevStep = currentStep - 1
    if (prevStep >= 0) {
      sendSegmentEvent(`Went back to step ${prevStep} in onboarding`)
      setCurrentStep(prevStep);
      window.scrollTo({ top: 0, left: 0 })
      const newSteps = steps.map((step, index) => {
        if (index < prevStep) return { status: STEP_STATUS.COMPLETED };
        if (index === prevStep) return { status: STEP_STATUS.CURRENT };
        return { status: STEP_STATUS.PENDING };
      })
      setSteps(newSteps);
    }
  }

  const largeScreenView = useMediaQuery({query: `(min-width: ${SIDEBAR_MIN_WIDTH}px)`})

  const sendSegmentEvent = useSendSegmentEvent()

  const currentScreen = showCelebrationScreen ? celebrationScreen : stepScreens[currentStep]
  const { MainContent: currentContent, sideDesktopContent, mobileContent } = currentScreen

  return (
    <InterstitialContext.Provider value={{
      largeScreenView,
      goToNextPane: advanceStep,
      goToPrevPane: goBackOneStep,
      currentStep,
      setCurrentStep,
    }}>
      <div css={css`
        display: flex;
        width: 100vw;
        min-height: 100vh;
        flex-direction: row;
        width: 100%;
        justify-content: center;
      `}>
        <div css={css`
          padding: 20px;
          flex-grow: 1;
          background-color: ${FC_BACKGROUND_GRAY};
          justify-content: center;
        `}>
          <div css={css`
            width: ${largeScreenView ? '500px' : '100%'};
            margin: 0 auto;
            height: 100%;
            align-items: center;
            justify-content: center;
          `}>
            <StepsStatus steps={steps} />
            {currentContent}
          </div>
        </div>
        {largeScreenView && sideDesktopContent && sideDesktopContent}
        {!largeScreenView && mobileContent && mobileContent}
    </div>
    </InterstitialContext.Provider>
  )
}

export const FullScreenOnboarding = FullScreenOnboardingDataWrapper