/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { shift, offset, flip } from '@floating-ui/react-dom';
import { autoUpdate, useFloating } from '@floating-ui/react-dom-interactions';
import { animated, useSpring } from '@react-spring/web'
import { useClickOutside } from '../../Sessions/Schedule/useClickOutside';

import { useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { BRONZE } from '../../emotionVariables'
import { usePrevious } from '../../utils'
import { HostGoalEntry } from './HostGoalEntry';
import { CreateSessionConfirmDetail } from './CreateSessionConfirmDetail';
import { DefaultBanner } from '../banners';
import Icon, { TYPE_CLOSE } from '../../daily/daily-components/Icon/Icon';
import { useSendSegmentEvent } from '../../wrappers/SegmentProvider';
import { sortBy } from '../../../functions/shared/helpers';
import { ShareSessionLinkWithImagePreview } from './ShareSessionLinkWithImagePreview';

export const usePopupData = () => {
  const options = {
    open: true,
    placement: 'left',
    strategy: 'fixed',
    middleware: [offset(12), flip(), shift({ padding: 32, crossAxis: true })],
    whileElementsMounted: autoUpdate,
  }

  const floatingUIData = useFloating(options);

  return floatingUIData
}

export const SessionDetailsPopup = ({ closePopup, floatingUIData, confirmDetailData }) => {
  const { x, y, floating, strategy, placement, middlewareData } = floatingUIData
  const floatingShiftOffset = middlewareData.shift
  const [dirty, setDirty] = useState(false)

  const initial = usePrevious(null) === undefined
  const clickOutsideRef = useClickOutside({
    onTriggered: () => {
      if (!initial && !dirty) {
        closePopup()
      }
    }
  })

  return (
    <>
      <div css={css`
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.4);`}/>
      <div
        ref={floating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        }}
        css={css`z-index: 9000;`}
      >
        <div ref={clickOutsideRef}>
          <SessionDetailsPopupWindow
            placement={placement}
            floatingShiftOffset={floatingShiftOffset}
            confirmDetailData={confirmDetailData}
            closePopup={closePopup}
            key={confirmDetailData.sessionStart.unix()}
            setDirty={setDirty}
            dirty={dirty}
          />
        </div>
      </div>
    </>
  )
}

const SessionDetailsPopupWindow = ({ placement, floatingShiftOffset = { x: 0, y: 0 }, confirmDetailData, closePopup, setDirty = () => {}, dirty = false}) => {
  const animatedStyle = useSpring({
    from: { opacity: 0.5, transform: `scale(0)` },
    to: { opacity: 1, transform: `scale(1)` },
  })
  
  return (
    <animated.div style={animatedStyle} css={css`
      padding: 48px 36px;
      background: white;
      border: 1px solid #E0E0E0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 447px;
      max-height: calc(100vh - 48px);
      overflow: auto;

      margin-bottom: 8px;

      ${css`transform-origin: ${placement === 'left' ? 'right' : 'left'} calc(50% - ${floatingShiftOffset.y}px);`}
    `}>
      <SessionDetailsPopupContent confirmDetailData={confirmDetailData} closePopup={closePopup} setDirty={setDirty}/>
      {dirty && <div onClick={() => { setDirty(false); closePopup(); }} css={css`
          position: absolute;
          top: 10px;
          right: 20px;
          cursor: pointer;
        `}>
          <Icon type={TYPE_CLOSE} />
        </div>}
    </animated.div>
  )
}

export const SessionDetailsPopupFullScreen = ({ confirmDetailData, closePopup }) => {
  const animatedStyle = useSpring({
    from: { opacity: 0.5, transform: `scale(0)` },
    to: { opacity: 1, transform: `scale(1)` },
  })

  return (
    <div css={css`
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0px;
      left: 0px;
      overflow: auto;
      z-index: 9000;
    `}>
      <animated.div style={animatedStyle} css={css`
        padding: 48px 8px;
        background: white;
        transform-origin: center;
        
        position: relative;
      `}>
        <SessionDetailsPopupContent confirmDetailData={confirmDetailData} closePopup={closePopup} />
        <div onClick={closePopup} css={css`
          position: absolute;
          top: 10px;
          right: 20px;
        `}>
          <Icon type={TYPE_CLOSE} />
        </div>
      </animated.div>
    </div>
  )
}

const SessionDetailsPopupContent = ({ confirmDetailData, closePopup, setDirty = () => {}}) => {
  const {
    sessionStart,
    user,
    adminModeEnabled,
    selectedHost,
    onDemandCreationInfo,
    visibility
  } = confirmDetailData
  let { eventTypes } = confirmDetailData
  const sendSegmentEvent = useSendSegmentEvent()

  const [createdSessionData, setCreatedSessionData] = useState({ createdSessionId: null, recurrences: null, ogImage: null, hostGoalDescription: null, hostGoalEmoji: null })
  const saveGoalsRef = useRef(null)
  const onCreateSession = (createdSessionId, recurrences, ogImage, hostGoalDescription, hostGoalEmoji) => {
    setCreatedSessionData({ createdSessionId, recurrences, ogImage, hostGoalDescription, hostGoalEmoji })
  }


  const goToSessionClicked = async () => {
    sendSegmentEvent("Go to Session Button Clicked")
    if (saveGoalsRef && saveGoalsRef.current) {
      await saveGoalsRef.current();
    }
  }

  const closePopupWithCallback = async () => {
    if (saveGoalsRef && saveGoalsRef.current) {
      await saveGoalsRef.current();
    }
    closePopup()
  }

  const createdSessionForSelf = createdSessionData.createdSessionId !== null && selectedHost.id === user.uid

  // We only have sortOrder for the v1 eventTypes, otherwise it's alphabetical, but we can still override this for v2 if we want
  eventTypes = sortBy(eventTypes, "sortOrder")

  const privateSession = visibility === 'private'

  return (
    <>
      {createdSessionData.createdSessionId === null ?
        <CreateSessionConfirmDetail
          sessionStart={sessionStart}
          onCreateSession={onCreateSession}
          eventTypes={eventTypes}
          user={user}
          adminModeEnabled={adminModeEnabled}
          selectedHost={selectedHost}
          onDemandCreationInfo={onDemandCreationInfo}
          setDirty={setDirty}
        />
      :
      <div>

        <Container>
        <DefaultBanner>
          <div css={css`
            text-align: left;
          `}>
            <span css={css`
              font-weight: bold;
            `}>
              Success!
            </span>
            <br/>
            {!privateSession ? 'Your session is on the schedule!' : 'You can now invite your guests.'}
          </div>
        </DefaultBanner>
        {createdSessionForSelf ?
          <>
            <HostGoalEntry
              eventId={createdSessionData.createdSessionId}
              sessionStart={sessionStart}
              recurrences={createdSessionData.recurrences}
              hostGoalDescription={createdSessionData.hostGoalDescription}
              hostGoalEmoji={createdSessionData.hostGoalEmoji}
              saveGoalsRef={saveGoalsRef}
            /> 
            <ShareSessionLinkWithImagePreview
              eventId={createdSessionData.createdSessionId}
              ogImage={createdSessionData.ogImage}
              inviteCode={user.inviteCode}
            />
            <div className="text-center mt-5 mobile-bottom">
              <a href={`/session/${createdSessionData.createdSessionId}`} target="_blank" rel="noreferrer">
                <Button onClick={goToSessionClicked} variant="primary" css={privateSession && css`background-color: ${BRONZE}; border-color: ${BRONZE};`}>
                  Go to session
                </Button>
              </a>
              <Button onClick={closePopupWithCallback} variant="outline-primary" className="ml-3">
                Close
              </Button>
            </div>
          </>
          :
          <CreatedSessionForOtherConfirmation eventId={createdSessionData.createdSessionId} goToSessionClicked={goToSessionClicked}/>
        }
        </Container>
      </div>
      }
    </>
  )
}

const CreatedSessionForOtherConfirmation = ({ eventId, goToSessionClicked }) => {

  return (
    <Container>
      <DefaultBanner>
        The session is now visible on the schedule. (unless it's a private session. your mileage may vary. terms and conditions apply. 🙃)
      </DefaultBanner>
      <div className="text-center mt-5 mobile-bottom">
        <a href={`/session/${eventId}`} target="_blank" rel="noreferrer">
          <Button onClick={goToSessionClicked} variant="primary">
            Go to Session
          </Button>
        </a>
      </div>
    </Container>
  )
}