
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router"
import { UserContext, useGetDisplayName } from "./UserProvider";
import { Badge, Container } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { FC_DARK_BLUE, SMOKE } from "./emotionVariables";
import { Helmet } from 'react-helmet'
import { useGetActiveHosts } from './fetch/endpoints'
import { useAutomaticallyFetch } from './fetch/helpers';
import { FlowButton, BUTTON_STYLES, LoadingIndicator, Text, TEXT_STYLES } from './components/flowComponents';
import { truncateTextWithEllipses } from '../functions/shared/helpers';
import { useSendSegmentEvent } from './wrappers/SegmentProvider';
import Icon, { TYPE_RIGHT_CARET_UNSET_FILL } from './daily/daily-components/Icon/Icon';
import { baseURL } from './firebase';
import { toTitleCase } from '../functions/shared/helpers';


const MobileViewMediaQuery = {query: '(max-width: 997px)'}

export const HostDirectory = () => {
  const { page = 1, badgeSlug = undefined } = useParams()
  const currentPage = parseInt(page)
  const mobileView = useMediaQuery(MobileViewMediaQuery)
  const pageSize = mobileView ? 5 : 20

  const { result, fetching } = useAutomaticallyFetch(useGetActiveHosts, { page: currentPage, pageSize, badgeSlug, communityData: true }, { dependencies: [currentPage] })
  const { hosts, totalCount } = result ?? { hosts: null, totalCount: 0 }


  return (
    <div>
      <Helmet>
        <title>Flow Club Hosts{badgeSlug !== undefined ? ` - ${toTitleCase(badgeSlug)}` : ''}</title>
        <meta name="description" content="Co-work with 700+ Flow Club Hosts" />
        <meta property="og:title" content={`Flow Club Hosts: Page ${currentPage}`} />
        <meta property="og:description" content="Co-work with 700+ Flow Club Hosts" />
        <link rel="canonical" href={`${baseURL}/hosts/${currentPage}`} />
      </Helmet>
      <div css={css`
        padding: ${mobileView ? '20px 0 10px 0' : '120px 0 75px 0'};
        background: ${SMOKE};
      `}>
        <Container css={css`
          text-align: center;
        `}>
        <Text style={TEXT_STYLES.MARKETING_H3} customCss={css`margin-bottom: ${mobileView ? 12 : 24}px;`}>{badgeSlug !== undefined ? `Hosts - ${toTitleCase(badgeSlug)}` : 'Meet our hosts'}</Text>

        <Text style={TEXT_STYLES.BODY_1}>Flow Club Hosts are members who have chosen to hold space and help facilitate everyone to do their best work in a limited amount of time. They are there to greet you every time you are ready to dive into work, ask you questions and curate music to help you achieve focus more quickly, and work alongside you so you feel supported and inspired.</Text>
      </Container>
      </div>
        {fetching ?
          <div css={css`
            width: 100vw;
            text-align: center;
            min-height: 60vh;
          `}>
            <LoadingIndicator />
          </div> :
          <Container css={css`
            margin-top: ${mobileView ? 12 : 48}px;
            text-align: center;
            position: relative;
            margin-bottom: 48px;
          `}>
            <div css={css`
              display: flex;
              flex-direction: ${mobileView ? 'column' : 'row'};
              flex-wrap: wrap;
              align-items: top;
              text-align: center;
              margin-bottom: 24px;
              gap: 24px;
            `}>
              {hosts && hosts.map(host => (
                <HostCard host={host} />
              ))}
            </div>
            <PagingControls
              badgeSlug={badgeSlug}
              totalCount={totalCount}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </Container>}
    </div>
  )
}

const HostCard = ({ host }) => {
  const mobileView = useMediaQuery(MobileViewMediaQuery)
  const { slug, image, headline, bio, location, favoriteeCount, communityFavorite, favoriteeCountNormalized } = host

  const hostDisplayName = useGetDisplayName(host)
  const headlineOrBioTeaser = headline || truncateTextWithEllipses(bio, 100)
  const sendSegmentEvent = useSendSegmentEvent()
  const hostProfileClicked = () => {
    sendSegmentEvent("Clicked host profile from directory", { hostId: host.id })
  }
  return (
    <Link to={`/host/${slug}`} onClick={hostProfileClicked}>
      <div css={css`
        ${!mobileView && css`flex-basis: 25%; max-width: 248px;`}
        text-align: center;
        padding: 24px;
        cursor: pointer;
        word-wrap: break-word;
        color: #000;
        &:hover {
          background: ${SMOKE};
        }
        `}>
        <img src={image} css={css`
          width: 200px;
          height: 200px;
        `} alt={hostDisplayName} />
        <div css={css`
          margin-top: 8px;
        `}>
          <Text style={TEXT_STYLES.APP_H4} customCss={css`margin-bottom: 12px;`}>{hostDisplayName}</Text>
          <Text style={TEXT_STYLES.BODY_2} customCss={css`margin-bottom: 12px;`}>{location}</Text>
          <Text style={TEXT_STYLES.BODY_2} customCss={css`margin-bottom: 24px;`}>{headlineOrBioTeaser}</Text>
          {favoriteeCount ?
            <>
            <Text>{favoriteeCount} members have favorited </Text>
            {communityFavorite && <Badge css={css`background-color: #D5E3E0;`}>⭐️ Community Favorite</Badge>}
            {favoriteeCountNormalized && <Text>{favoriteeCountNormalized.toFixed(1)} favoritees per 100</Text>}
          </> :
          ""}
        </div>
      </div>
    </Link>
  )
}

const PagingControls = ({ badgeSlug, totalCount, currentPage, pageSize }) => {
  const linkPrefix = badgeSlug !== undefined ? `/club/${badgeSlug}/hosts` : '/hosts'
  const mobileView = useMediaQuery(MobileViewMediaQuery)
  const PAGES_TO_SHOW = mobileView ? 1 : 3
  const history = useHistory()
  const sendSegmentEvent = useSendSegmentEvent()
  const pageHostsBackwards = () => {
    sendSegmentEvent("Paged host directory backwards", { page: currentPage })
    history.push(`${linkPrefix}/${currentPage - 1}`)

  }
  const pageHostsForwards = () => {
    sendSegmentEvent("Paged host directory forwards", { page: currentPage })
    history.push(`${linkPrefix}/${currentPage+ 1}`)
  }
  const totalPages = Math.ceil(totalCount / pageSize);
  const startPage = Math.max(currentPage - PAGES_TO_SHOW, 2);
  const endPage = Math.min(currentPage + PAGES_TO_SHOW, totalPages - 1);


  return (
    <div css={css`display: flex; flex-direction: column; gap: 12px; max-width: 100%; margin: 0 10px;`}>
      <div css={css`display: flex; justify-content: center; gap: 12px;`}>
        {!mobileView &&<FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={currentPage === 1} onClick={pageHostsBackwards} customCss={css` cursor: pointer;`}>
          <div css={css`display: flex; gap: 12px; align-items: center;`}>
            <Icon css={css`stroke: ${FC_DARK_BLUE}; transform: rotate(180deg); ${currentPage === 1 && css`stroke: #767676;`}`} type={TYPE_RIGHT_CARET_UNSET_FILL} />
            Previous
          </div>
        </FlowButton>}
        <Link to={`${linkPrefix}/1`}>
          <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={currentPage === 1} customCss={css` cursor: pointer;`}>
            1
          </FlowButton>
        </Link>
        {startPage > 2 && <span>...</span>}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
        const pageNum = startPage + i;
        return (
          <Link key={pageNum} to={`${linkPrefix}/${pageNum}`}>
            <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={pageNum === currentPage} customCss={css` cursor: pointer;`}>
              {pageNum}
            </FlowButton>
          </Link>
        );
        })}
        {endPage < totalPages - 1 && <span>...</span>}
        {totalPages > 1 && (
        <Link to={`${linkPrefix}/${totalPages}`}>
          <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={currentPage === totalPages} customCss={css` cursor: pointer;`}>
            {totalPages}
          </FlowButton>
        </Link>
        )}
  
        {!mobileView && <FlowButton buttonStyle={BUTTON_STYLES.OUTLINE_DARK} disabled={currentPage === totalPages} onClick={pageHostsForwards} customCss={css` cursor: pointer;`}>
          <div css={css`display: flex; gap: 12px; align-items: center; cursor: pointer;`}>
            Next
            <Icon css={css`stroke: ${FC_DARK_BLUE}; ${currentPage === totalPages && css`stroke: #767676;`}`} type={TYPE_RIGHT_CARET_UNSET_FILL} />
          </div>
        </FlowButton>}
      </div>
    </div>
  );
};